/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  newUrl = '';
  auxId = null;

  constructor(public generalService: GeneralService, private router: Router) {}

  ngOnInit() {
    //this.generalService.sendToMain({type:'on-ionic-hello', data: 'header'});
    //this.generalService.state = 'browser'; // esto lo dejo por las dudas (Chinu)
    window.addEventListener('on-enter-keydown',  e => {
      if(this.newUrl === '') {return;}
      this.onBrowser('on-search-url');
    });
  }

  onButton() {
    if(this.generalService.terminalStatus ==='SUSPENDED'  || this.generalService.terminalStatus ==='DISCONNECTED') {return;}
    (this.generalService.state==='home')? this.generalService.sendToMain({type:'on-exchange', data: true}):this.generalService.sendToMain({type:'on-navigate-to', data: 'home'});
    this.clearInput();
  }

  onBrowser(browsertype) {
    if(this.generalService.terminalStatus ==='SUSPENDED'  || this.generalService.terminalStatus ==='DISCONNECTED') {return;}
    let aux;
    (browsertype==='on-search-url')? aux=this.newUrl: aux=true;
    this.generalService.sendToMain({type:browsertype, data: aux});
    this.clearInput();
  }

  clearInput() {
    this.auxId = setTimeout(() => {
      this.newUrl = '';
      clearTimeout(this.auxId);
      this.auxId = null;
    }, 800);
  }

}
