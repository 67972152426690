import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPageComponent } from './modal-page/modal-page.component';
import { IonicModule } from '@ionic/angular';
import { ClockComponent } from './clock/clock.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';

@NgModule({
  declarations: [ModalPageComponent, ClockComponent, HeaderComponent, ModalDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    //ModalDialogComponent //descomentando: Uncaught Error: Type ModalDialogComponent does not have 'ɵmod'
  ],
  exports: [
    ModalPageComponent,
    ClockComponent,
    HeaderComponent,
    ModalDialogComponent
  ]
})
export class ComponentsModule { }
