import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit {

  timeToShow = '';
  idTimeout: any;

  constructor() {
    this.startTime();
   }

  ngOnInit() {}

  startTime() {
    const today = new Date();
    const h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();
    m = this.checkTime(m);
    s = this.checkTime(s);
    this.timeToShow = h + ':' + m + ':' + s;
    clearTimeout(this.idTimeout);
    this.idTimeout = setTimeout(() => {
      this.startTime();
    }, 1000);
  }

  checkTime(i) {
    if (i < 10) {i = '0'+ i;};  // add zero in front of numbers < 10
    return i;
  }
}
